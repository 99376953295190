let eresa_v2 = true;
// Variable for trigger menu and header elements
const topSearchV2 = '#top_searchV2';
const leftMenu = '#left_menu';
const filterMenu = '#filters_menu';
const mainMenuBtn = '#main_menu_btn';
const userBtn = '#user_btn';
const accountMenu = '#account_menu';
const shade = '#shade';
const bannerNav = '.banner_nav';
const eResaBox = '#e-resaBox';

function openMultiShad(id) {
    $("#" + id).addClass("actif");
    $("body").addClass("noscroll");

    if (!$("#shade").hasClass("actif")) {
        $("#shade").addClass("actif");
    }

    if (id != "lightbox_preco") {
        $("#shade").on("click", function () {
            closeMultiShad(id);
        });
    }

    if ($("body").hasClass("wishlist")) {
        $('#shade.modal_shade').hide().addClass("actif").fadeIn(150);
    }
}

function closeMultiShad(id) {
    $("#" + id).removeClass("actif");
    $("#shade").removeClass("actif");
    $("body").removeClass("noscroll");
    $(".modbox").removeClass("open");

    if (id === "zoomBox") {
        $('#zoomBox').removeClass("active");
    }
}

function switchToggleCartpos() {
    if ($(".checkout").length) {
        $(".total_produit.toggle").insertBefore($(".bank_left_side"));
        $(".total_produit_dropdown").insertBefore($(".bank_left_side"));
    } else {
        $(".total_produit.toggle").insertBefore($(".left_side.elem_tunnel"));
        $(".total_produit_dropdown").insertBefore($(".left_side.elem_tunnel"));
    }
}


function switchAddrRecapCartpos() {
    if ($(".checkout").length) {
        $(".wrapper_payment").insertBefore($(".bank_left_side"));
    } else {
        $("#blocs_address").insertBefore(".left_side.elem_tunnel");
    }
}

// Toggles filters submenu visibility
function toggleFiltersMenu() {

    var open = $(this).closest('li').siblings('li').find('legend.open').get(0);

    this.classList.toggle('open');

    if (this.classList.contains('open')) {
        $(this).next('.filter_options').slideDown(400);
    } else {
        $(this).next('.filter_options').slideUp(400);
    }
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form);
    var type = value("type_tri"),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById("wrapper_couleur_filtre").innerHTML =
                filterformColors;
            document.getElementById("wrapper_taille_filtre").innerHTML =
                filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $("#filters_menu input[checked]")
            .not("#wrapper_sscat_filtre input[checked]")
            .removeAttr("checked"); // Force removal of all checked filters (except sub-category), which happens when returning from a FP.
    }
    $("legend.open", form).each(function () {
        toggleFiltersMenu.call(this);
    });

    // Check obj type
    if (form.sscat) {
        // Note: these are not subcategories but actual objects (views_mobile_new/wrapper_filters.php)

        idObjTmp = value("sscat");

        if (idObjTmp && idObjTmp.length > 0) {
            type = "obj";
            $("#object_id").val(idObjTmp);
        } else {
            type = "subcat";
        }
    }

    $("#type_tri").val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (
        btn.nextElementSibling &&
        btn.nextElementSibling.classList.contains("button")
    ) {
        btn.nextElementSibling.style.display = "block";
    }

    // Get new elements
    if (from_type === "product") {
        generateNewBlocProd.call(form, 0, type, idObj, value("nb_prod"), btn);
    } else {
        generateNewBlocSearch.call(form, value("nb_prod"), 0, btn);
    }

    return false;
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;

    let idObj = getCurrentIdRayon();
    let bloc_page = page - 1;

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type === "product") {
        generateNewBlocProd(
            bloc_page,
            null,
            idObj,
            null,
            null,
            fromScrollBottom,
            fromScrollTop,
            true
        );
    } else {
        generateNewBlocSearch(
            document.getElementById("nb_prod").value,
            bloc_page,
            null,
            fromScrollBottom,
            fromScrollTop
        );
    }
}

function paginate(page) {
    var form = document.getElementById("filters_form"),
        total_element = parseFloat($("#totalElems").val()),
        nb_prod = parseFloat($("#nb_prod").val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        nb_prod_default = parseFloat($("#nb_prod_default").val()),
        pagination = $(".wrap_flitre_pager"),
        see_all = parseFloat($("#see_all").val()),
        thisPage = parseInt(page / nb_prod),
        initialPage = thisPage;

    page = page || 1;

    $("#page").change(function () {
        page = parseFloat($("#page").val());
        thisPage = parseInt(page / nb_prod + 1);
    });

    $.ajax({
        url: path_relative_root + "ajax_reload_pagination.php",
        type: "get",
        data: {
            page: page,
            link: "paginationGoToPage()",
            nb_total_page: nb_total_page,
        },
        success: function (res) {
            var productLoaded = $(
                "#scroll_items .item_container .item:not('.push')"
            ).length;

            if (productLoaded < total_element) {
                pagination.each(function () {
                    if (see_all === 0) {
                        this.innerHTML =
                            '<div class="pagerNav end_pagi">' +
                            '<div class="loader_scroll">' +
                            '<button class="see_all_product button primary"><span>' +
                            Translator.translate("see_more_products") +
                            "</span></button>" +
                            '<div class="button primary loader"></div>' +
                            "</div></div>";
                    } else {
                        this.innerHTML = "";
                    }
                });
            }

            $(".loader_scroll .see_all_product").on("click", function () {
                $(this).addClass("loading");

                if (
                    $(".dynasearchwrapper").length === 0 &&
                    $('#scroll_items .item:not(.push)').length < total_element
                ) {
                    if (initialPage >= 2 && thisPage + 1 === initialPage) {
                        paginationGoToPage(thisPage + 2, true, false);
                    } else {
                        paginationGoToPage(thisPage + 1, true, false);
                    }
                }
            });

            if (productLoaded >= total_element) {
                $(".see_all_product").hide();
                $(".wrap_flitre_pager").hide();
            }
        },
    });
}

function generateNewBlocProd(
    page,
    type,
    idObj,
    nb_product,
    btn,
    fromScrollBottom,
    fromScrollTop,
    fromPagination = false
) {
    var form =
        this instanceof HTMLFormElement
            ? this
            : document.getElementById("filters_form"),
        page = page !== undefined ? page : form.getValue("page", parseFloat),
        type = type || form.getValue("type"),
        idObj = idObj || form.getValue("idObj"),
        nb_product = nb_product || form.getValue("nb_prod", parseFloat),
        startPage = parseInt($("#page_start").val()),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat) ? form.getValue('nb_prod_default', parseFloat) : $('#filters_form #nb_prod_default').val(),
        is_from_page = false,
        key,
        output = {};

    if ($(".see_all")) {
        $(".see_all").addClass("loading");
    }

    output = triggerFilter(
        page,
        type,
        idObj,
        nb_product,
        "filters_form",
        fromPagination
    );

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    let currentPage = $('#initial_page').val();
    output.current_page = parseInt(currentPage) + 1;

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // If price exist
    if ($("input[name=price_min]").length > 0) {
        // Retrieval of the price

        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    output.page = fromPagination ? $('#list_item .item').length : 0;
    output.nb_prod = nb_prod_default; // Since we don't have more pushs than on page 1, we need to load the default nb of products per page

    output.handle_push = 'false';

    //  Exception on page 1 // SW-17922 remove exception because creating product doublons in page 2 (keept it just in case)
    // if (page == 1) {
    //     var nb_pushs = $('#list_item .push').length;
    //     output.page = nb_prod_default - nb_pushs;
    // }

    $.ajax({
        url: path_relative_root + "ajax_reload_products.php",
        type: "get",
        data: output,
        success: function (res) {
            var old_elm = document.getElementById('list_item');
            var content, itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                (btn.nextElementSibling.classList.contains("btn_tunnel") ||
                    btn.nextElementSibling.classList.contains("loader"))
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();
            itm_length = parseFloat(res[1]);

            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

            // Specific to Kujten which only has the language EN in addition to FR

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev', seo, $("#lightbox_achat_express_v2"), $(".textSeo"));
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if (content !== "") {
                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById("nbr_items").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $("#scroll_items .item_container:first");
                    var curOffset =
                        firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML("afterbegin", content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                } else {
                    old_elm.insertAdjacentHTML("beforeend", content);
                }

                if (parseFloat($("#filters_menu").css("right")) === 0) {
                    toggleMenu("filters", "right");
                }

                if (window.lazyload || typeof lazyload != "undefined") {
                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            }
        },
        complete: function () {
            $("#totalElems").trigger("change");
            $("#bottom_reached").val("0").trigger("change");
            $("#page").trigger("change");

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate(
                        {
                            scrollTop: $(window).scrollTop() + 10,
                        },
                        200
                    );
                }, 250);
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#page_start").val(startPage + 1);

                if ($("#page_start").val() >= 1) {
                    $("#initial_page").val(page);
                }
            }

            setTimeout(function () {
                if (
                    $(btn).closest(".drawer").length &&
                    $(btn).closest(".drawer").attr("id")
                ) {
                    var menuId = $(btn)
                        .closest(".drawer")
                        .attr("id")
                        .replace("_menu", "");
                    toggleMenu(menuId);
                }
            }, 200);

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $(
                ".list_item .item_container:not(.push)"
            ).length;
            var percentLoaded =
                (productLoaded * 100) / parseInt(output.nb_tot_prod);


            $(".see_all_product").removeClass("loading");

            if (productLoaded >= document.getElementById("totalElems").value) {
                $(".see_all_product").hide();
                $(".wrap_flitre_pager").hide();
            }
        },
    });
}

/********* FONCTIONS RAYON **********/

function initItemSlider(isToggled) { // init categ item swiper, isToggled is a boolean to check if the function is called from a toggle view action

    if ($('.prodItemSlider, .searchItemSlider').length) {

        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) { // Toggle view is triggerred

            setTimeout(function () {

                $('.prodItemSlider, .searchItemSlider').each(function () {

                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll

            $("#list_item .item").each(function (e) {
                var this_item_rayon = $(this).attr('id');

                if (!$(this).find('.linkImgWrapper .swiper-container').hasClass('initialized') && $(this).find('.linkImgWrapper .swiper-slide').length > 1) {
                    var swiper_rayon_mobile_each = new Swiper('#' + this_item_rayon + ' .swiper-container', {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        onInit: function () {
                            $('#list_item').fadeTo('slow', 1);
                        },
                    });

                    $(this).find('.prodItemSlider').addClass('initialized');

                } else {
                    $('#' + this_item_rayon + ' .swiper-container').fadeTo('slow', 1);
                    $('#list_item').fadeTo('slow', 1);
                }
            });
        }
    }
}

/**
 * Load global .item_container slider initialization on window scroll
 */
window.addEventListener("scroll", function () {
    initItemSlider(false);
});

/* Initialisation des JS pour la recherche V2 */
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);

    if ($('.swiper.three_pictures').length) {
        // Initialization of swiper module 2 home

        initializeSwiper('.swiper.three_pictures', {
            preloadImages: false,
            slidesPerView: 1.5,
            spaceBetween: 10,
        });
    }

    // Initialization of swiper menu categ in search

    if ($('.menu_filter_wrapper .swiper-container').length) {

        var menuInitialSlide = 0;
        $(".menu_filter_wrapper .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("current")) {
                menuInitialSlide = i;
            }
        });

        initializeSwiper('.menu_filter_wrapper .swiper-container', {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 10,
            initialSlide: menuInitialSlide,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
            onInit: function () {
                $(".menu_filter_wrapper").fadeTo("slow", 1);
            },
        });

        window.scrollBy(0, 1);
    }

    swipeDownClose(); // init swipedown close for modals on loaded product

    $('.modal_shade.visible').click();
}


function toggleViewMobile() {
    $("div.toggleNumObjects span").toggleClass("actif");
    if ($("#oneProduct").hasClass("actif")) {
        $(".twoProducts").removeClass("actif");
        $("#list_item").addClass("full");
        createCookie("nb_produits", 1, 1);
    } else {
        $("#list_item").removeClass("full");
        $(".oneProduct").removeClass("actif");
        createCookie("nb_produits", 2);
    }

    if ($(".dynasearchwrapper").length) {
        initItemSlider(true);
    } else {
        initItemSlider(false);
    }

}

/********* FONCTIONS SEARCH **********/
function close_search() {
    $('#search_trigger').click();
    $('body').removeClass('noscroll');
}

function searchCategoryFilter() {
    window.setTimeout(function () {
        new Swiper("#slideCateg", {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 10,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
        });
    }, 500);
}

/********* FIN FONCTIONS SEARCH **********/

$(function () {

    /********** SWIPERS INITIALIZATION *********/

    //initialization of the list item product swiper
    initItemSlider(false);

    if ($('.homepage').length) {

        // Initialization of swiper module 1 home

        initializeSwiper('.sliderMainHome', {
            pagination: '.cover-pagination',
        });

        // Initialization of swiper module 2 home

        initializeSwiper('.swiper.three_pictures', {
            preloadImages: false,
            slidesPerView: 1.5,
            spaceBetween: 10,
        });

        // Initialization of swiper module 8 home

        initializeSwiper('.home_module.pictures_text', {
            slidesPerView: 1,
            nextButton: '.pictures_text-next',
            prevButton: '.pictures_text-prev',
            pagination: ".pictures_text-pagination",
        });

    }

    if ($('.category').length) {
        // Initialization of swiper module 2 home

        initializeSwiper('.swiper.three_pictures', {
            preloadImages: false,
            slidesPerView: 1.5,
            spaceBetween: 10,
        });
    }

    // Init swiper slider on the page
    initSwiperSlide();

    if ($('.faq.mobile').length) {

        initializeSwiper('.faq_container-swiper', {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 10,
            slidesOffsetAfter: 10,
        });
    }

    // Initialization of swiper reinsurance footer

    initializeSwiper('.swiper_reinsurance', {
        slidesPerView: 1,
        pagination: '.reinsurance-pagination',
        autoplay: 3000,
        speed: 600,
    });

    // Initialization of swiper menu categ

    if ($('.menu_filter_wrapper .swiper-container').length) {

        var menuInitialSlide = 0;
        $(".menu_filter_wrapper .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("current")) {
                menuInitialSlide = i;
            }
        });

        initializeSwiper('.menu_filter_wrapper .swiper-container', {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 10,
            initialSlide: menuInitialSlide,
            slidesOffsetBefore: 10,
            slidesOffsetAfter: 10,
            onInit: function () {
                $(".menu_filter_wrapper").fadeTo("slow", 1);
            },
        });
    }

    swipeDownClose();

    //Load only if the page has the class '.satellite_store_details' and the swiper has more than 1 slide
    if ($('.satellite_store_details').length) {

        // Initialisation swiper detail store locator
        initializeSwiper('.store_img', {
            slidesPerView: 1,

        });
    }

    // Preheader
    window.setTimeout(function () {
        if ($(".promo_swiper").length || $("#sliderBando").length && $('.bandeauMenuAffix').is(':visible') || $('#sliderBandoWpos').length) {
            $("body").addClass("hasBando");
        } else {
            $("body").removeClass("hasBando");
        }
    }, 1000);

    if ($("#sliderBando").length) {
        if ($("#sliderBando .swiper-wrapper .swiper-slide").length > 1) {
            setTimeout(function () {

                new Swiper("#sliderBando", {
                    loop: true,
                    autoplay: 3000,
                    speed: 2200,
                    effect: "slide",
                    autoplayDisableOnInteraction: false,
                    direction: "vertical",
                });
            }, 800);
        } else {
            new Swiper("#sliderBando", {
                slidesPerView: 1
            });
        }
    }

    // Account menu slider
    if ($(".wrapper_menu_compte_container .wrapper_menu_compte.swiper-wrapper .swiper-slide".length > 1)) {
        var menuAccountInitialSlide = 0;
        $(".wrapper_menu_compte_container .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("actif")) {
                menuAccountInitialSlide = i;
            } else {
                i++;
            }
        });
        initializeSwiper('.wrapper_menu_compte_container', {
            slidesPerView: 'auto',
            freeMode: true,
            spaceBetween: 15,
            initialSlide: menuAccountInitialSlide,

            onInit: function () {
                $(".wrapper_menu_compte_container").fadeTo("slow", 1);
            },
        });
    }

    // Call to functions related to the display and animation of the header

    triggerMenuLeft();
    triggerMenuRight();
    triggerMenuFilter();
    triggerSearch();
    clickOnShade();

    // Call to functions related to the display and animation of the left menu

    toggleAccordeonLeftMenu();
    toggleAccordeonSubLeftMenu();

    // Call to functions related to the display and animation of the footer

    toggleAccordeonFooter();

    // Call to function related to the display of cart and wishlist in tunnel
    cart_switch_view();

    /**
     * Init function if '.formPanier_container' available on the current page
     **/
    if ($(".formPanier_container").length) {
        setTimeout(function () {
            formPanierClass();
        }, 500);
    }

    /**
     * Switch toggle details line position
     */
    if ($(".total_produit.toggle").length) {
        switchToggleCartpos();
    }

    if ($("#blocs_address").length || $(".wrapper_payment").length) {
        switchAddrRecapCartpos();
    }

    /*
    * Main menu accordion sscat
    * */
    $(document).on("click touch", ".displayAccordion", function () {
        var btnClicked = $(this);
        var nextUl = $(this).next(".sub_cat_menu");

        if (nextUl.hasClass("displayed")) {
            btnClicked.removeClass("opened");
            nextUl.slideUp(400, function () {
                nextUl.removeClass("displayed");
            });
        } else {
            btnClicked.addClass("opened");
            nextUl.slideDown(600, function () {
                nextUl.addClass("displayed");
            });
        }
    });

    //seo bloc end page rayon toggle
    $('.seo_div .read_more, .seo_div .read_less').on('click touch', function () {
        if ($(this).parents('.seo_div').find('.full').hasClass('cache')) {
            $('.seo_div .trimed').addClass('cache');
            $(' .seo_div .trimed').removeClass('displayed');
            $(' .seo_div .full').addClass('displayed');
            $(' .seo_div .full').removeClass('cache');
        } else {
            $(' .seo_div .trimed').addClass('displayed');
            $(' .seo_div .trimed').removeClass('cache');
            $('.seo_div .full').addClass('cache');
            $(' .seo_div .full').removeClass('displayed');
        }
    })

    // Function related to the display of the sticky header
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= 25) {
            $(".banner_wrapper").addClass("sticky");
            $("body").addClass("scrolled");
        } else {
            $(".banner_wrapper").removeClass("sticky");
            $("body").removeClass("scrolled");
        }
    });

    // tunnel V3 size modal toggle
    $('body').on('click', '.toggle_modal_actions', function () {
        $(this).parents('.product_line_basket').find('.cart_item_actions').addClass('actif');
        if (!$(this).parents('.product_line_basket').find('.cart_item_actions .titre_choices_list').length) {
            var modal_cart_item_actions = $(this).parents('.product_line_basket').find('.cart_item_actions');
            var modal_cart_size_title = $("<div class='titre_choices_list'>" + Translator.translate('modify_size') + "</div>");

            modal_cart_size_title.prependTo(modal_cart_item_actions);
            swipeDownClose();

        }
        if (!$('body').hasClass('noscroll')) {
            $('body').addClass('noscroll');
        }

        if (!$('#shade').hasClass('visible')) {
            $('#shade').addClass('visible');
        }
    });

    $('body').on('click', '.cart_item_actions_form .productSizeFieldset input', function () {
        setTimeout(function () {
            $('#shade').removeClass('visible');
        }, 3000);
    });

    /**
     * Toggle product details in cart right column
     */
    $(".total_produit.toggle").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    //
    $("#cart_slider_container").insertAfter($("#cart_total"));

    /* Hide cart summary for new customer step 2 */
    if ($('.step_2_0').length) {
        $('#cart_total').hide();
    }

    $(".form_itm_wrapper .form_itm.size").on("click", function () {
        if ($(".error_text.error").hasClass("error")) {
            $(".error_text.error").removeClass("error");
        } else {
            $(".error_text.noSizeSelected").hide();
        }
    });

    // lookbook tag tooltip display function
    $('.productTag').click(function () { //if product tag is clicked, we hide all the tooltips and display the one related to the clicked tag
        var check = $(this).attr('data-ref');
        $('.tag_tooltip').removeClass('actif');
        $(this).parents('.item_look_contents').find(".tag_tooltip[data-ref='" + check + "']").addClass('actif');
    });

    $('.tag_tooltip').click(function () { //if tooltip is clicked, we hide it
        $('.tag_tooltip').removeClass('actif');
    });

    // lookbook look5 swiper case
    var $look5 = $('.look5');
    var counterLook5 = 0; // Initialiser le compteur pour les ID uniques

    for (var i = 0; i < $look5.length; i += 3) {
        // Select every .look5 element in groups of 3
        var $group = $look5.slice(i, i + 3);

        // checks if the group has 3 elements
        if ($group.length === 3) {
            // wrapping the elements in a div.swiper-wrapper
            $group.addClass('swiper-slide');
            $group.wrapAll('<div class="swiper-wrapper"></div>');

            // wrap the div.swiper-wrapper inside an other div with a unique value id
            $group.parent().wrap('<div id="swiper-container-' + counterLook5 + '" class="swiper-look5"></div>');

            // Sélectionne le conteneur swiper-look5 actuel
            var $swiperLook5Container = $('#swiper-container-' + counterLook5);

            // Ajoute les éléments de pagination et de navigation au conteneur swiper-look5
            $swiperLook5Container.append('<div class="swiper-pagination"></div>');
            $swiperLook5Container.append('<div class="swiper-button-prev"></div>');
            $swiperLook5Container.append('<div class="swiper-button-next"></div>');

            // Swiper init for each group
            new Swiper('#swiper-container-' + counterLook5, {
                slidesPerView: 1,
                pagination: '#swiper-container-' + counterLook5 + ' .swiper-pagination',

                nextButton: '#swiper-container-' + counterLook5 + ' .swiper-button-next',
                prevButton: '#swiper-container-' + counterLook5 + ' .swiper-button-prev',
            });

            counterLook5++; // Incrémenter le compteur après chaque groupe traité
        }
    }

    /*Call the function everytime a change occurs on the cart page*/
    updateCartUI();

    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            updateCartUI();
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // For Hompage text modules, adds more space between title and text [WP-28349]
    if ($('body.homepage').length) {
        $('.home_module.text span:has(> strong:only-child) > strong, .home_text_container span:has(> strong:only-child) > strong').css({
            'display': 'inline-block',
            'margin-bottom': '20px'
        });
    }

    $('.slider').each(function () {
        if ($(this).find('.swiper-slide.encart4').length > 1) {
            initializeSwiper(this, {
                slidesPerView: 1,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                pagination: '.slider-pagination',
            });
        }
    });

    if ($('.store_horaire .holiday').length) {
        let newDiv = $("<div>").text(Translator.translate('horaires_execp'));

        newDiv.addClass("title");
        $('.store_horaire .holiday').append(newDiv);
    }

    // diaply size selection for disponibility in store check
    $("#bloc_availability").on("click", function () {
        if ($("#bloc_availability").hasClass("inactiv")) {
            sizeChooseBlink();
        }
    });

    if ($('body.cart.step_1 #gondole_derniers_articles_vus').length) {
        $('#gondole_derniers_articles_vus').insertAfter('#cart_total');

        setTimeout(function () {
            if ($("#productVisitedSwiper .swiper-wrapper .swiper-slide").length > 1) {
                new Swiper("#productVisitedSwiper", {
                    slidesPerView: 2.9,
                    spaceBetween: 10,
                    pagination: '#gondole_derniers_articles_vus .visited-pagination',
                    slidesOffsetBefore: 28,
                });

                $('#productVisitedSwiper').addClass('swiperSetLeft');
            }
        }, 500);
    }
});

function sizeChooseBlink() {
    const bloc_add_basket = $('#btn_add_cart');

    // Add a blink effect to the size selection
    bloc_add_basket.addClass('blink');
    setTimeout(function () {
        bloc_add_basket.removeClass('blink');
    }, 3000);
}

var basketSizeSwiperElmt = null;

function basketSizeSwiper(formElmtID) {
    setTimeout(function () {
        var swiperElmt = $('#' + formElmtID).find('.prod_listes.swiper-container');

        if ($(swiperElmt).find('.swiper-slide').length > 8) {
            basketSizeSwiperElmt = new Swiper($(swiperElmt), {
                slidesPerView: 8.4,
                spaceBetween: 3,
            });
        }
    }, 800);
}

// function to close modbox on swipe down

function swipeDownClose() {
    var start_point_Y;
    $('.rollover.achat_express, #item_added, .cart_item_actions.actif, #modboxpromo, #cartSizeInfoLightbox').on('touchstart', function (e) {
        start_point_Y = e.originalEvent.touches[0].pageY;
    });

    $('.rollover.achat_express, #item_added, .cart_item_actions.actif, #modboxpromo, #cartSizeInfoLightbox, .selection-offer').on('touchend', function (e) {
        var endY = e.originalEvent.changedTouches[0].pageY;
        var deltaY = endY - start_point_Y;
        var swipeThreshold = 15;

        if (deltaY > swipeThreshold) {
            $('body').removeClass('noscroll');
            $('#shade').click();
            // $('.close_modal').click();
            $('.close_pop').click();
            $('.cart_item_actions_close.close').click();
            modBoxClose.call(document.getElementById('item_added'));
        }
    });
}

$(window).on("removeBasketItemCustom", function (data) {
    $('.cart_item_actions_close.close').click();
});

// override app function to fit up to date version of cart product changes modal management
function hideModalAction(evt) {
    var shade = $('#shade');
    var modal = $(".cart_item_actions");
    if (typeof evt !== "undefined") evt.preventDefault();
    shade.off('touchend', modBoxClose).removeClass('visible');
    modal.removeClass('actif');
    $('body').removeClass('noscroll');
    modal.fadeOut('fast');
    $(document).find('.wrapper_cart_product_desc').find('.toggle_modal_actions').fadeIn('fast');
}

// Function related to the display of header menus that fixes the issues of menu overlap and the search bar.

// Function to handle the right menu trigger (account menu)
function triggerMenuRight() {
    $(document).on('click tap touch', '#user_btn .user', function () { // triggered right
        // handle other menus close trigger
        $(topSearchV2).removeClass('show visible');
        $(leftMenu).removeClass('open');
        $(mainMenuBtn).removeClass('menu_open');
        $(filterMenu).removeClass('open');
    });
};

// Function to handle the left menu trigger (main menu)
function triggerMenuLeft() {
    $(document).on('click tap touch', '#main_menu_btn .menu', function () { // triggered left
        // handle other menus close trigger
        $(topSearchV2).removeClass('show visible');
        $(userBtn).removeClass('active');
        $(accountMenu).removeClass('active');
        $(filterMenu).removeClass('open');
    });
};

// Function to handle the filter menu trigger (filters menu)
function triggerMenuFilter() {
    $(document).on('click tap touch', '.filters_cmd', function () { // triggered filter
        // handle other menus close trigger
        $(topSearchV2).removeClass('show visible');
        $(leftMenu).removeClass('open');
        $(userBtn).removeClass('active');
        $(accountMenu).removeClass('active');
    });
};

// Function to handle the search activation trigger
function triggerSearch() {
    $(document).on('click tap touch', '#search_trigger', function () { // triggered search
        if ($(topSearchV2).hasClass('show')) { // search menu close trigger
            $(topSearchV2).removeClass('show');
            $(bannerNav).removeClass('search_on');
            $(shade).removeClass('visible');
        } else { // handle search menu open trigger + other menus close trigger
            $('body').removeClass('search_hidden');
            $(shade).addClass('visible');
            $(accountMenu).removeClass('active');
            $(leftMenu).removeClass('open');
            $(filterMenu).removeClass('open');
            $(mainMenuBtn).removeClass('menu_open');
            $(topSearchV2).addClass('show');
            $(bannerNav).addClass('search_on');
        }
    });
};

// Function to close the search panel
function closeSearch() {
    $(topSearchV2).removeClass('show');
    $(bannerNav).removeClass('search_on');
    $(shade).removeClass('visible');
}

// Function to handle clicks on the shade (overlay)
function clickOnShade() {
    $(document).on('click tap touch', '#shade', function () {
        $(userBtn).removeClass('active');
        $(accountMenu).removeClass('active');
        $(leftMenu).removeClass('open');
        $(shade).removeClass('visible');
        $(topSearchV2).removeClass('show visible');
        $(eResaBox).removeClass('open');
        $('body').removeClass('noscroll');
        $(document).find('.cart_product_desc').find('[id^=js-modal-action-]').fadeOut('fast');
        $(document).find('.wrapper_cart_product_desc').find('.toggle_modal_actions').fadeIn('fast');
    });
};

function toggleAccordeonFooter() {
    $(document).on("click touch", ".footer_container .navlist", function () {
        if ($(this).hasClass("displayed")) {
            $(this).removeClass("displayed");
        } else {
            $(this).addClass("displayed");
        }
    });
};

function toggleAccordeonLeftMenu() {
    $(document).on('click tap touch', '.menu_part.bottom .first_level_item', function () {
        if ($(this).closest('.main_menu_itm').hasClass('active')) {
            $(this).closest('.main_menu_itm').removeClass('active');

        } else {
            $(this).closest('.main_menu_itm').addClass('active');
        }
    });
};

function toggleAccordeonSubLeftMenu() {
    $(document).on('click tap touch', '.menu_part.bottom .menu_subcategory', function () {
        if ($(this).hasClass('sub_menu_open')) {
            $(this).removeClass('sub_menu_open');
        } else {
            $('.menu_subcategory').removeClass('sub_menu_open');
            $(this).addClass('sub_menu_open');
        }
    });
};

function getWishlistProducts() {
    let array_elements = document.querySelectorAll("a[data-productid]");
    let array_ids = [];
    if (typeof array_elements != "undefined" && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute("data-productid");
            if (id != "" && id != "undefined") {
                array_ids.push(id);
            }
        }
        let data = JSON.parse(JSON.stringify(Object.assign({}, array_ids)));
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_all_wishlist.php",
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != "undefined" && response.length > 0) {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $(
                                'a[data-productid = "' +
                                array_product_id[product_wishlist]
                                    .fk_produit_id +
                                '"]'
                            ).addClass("existToWishlistButton");
                            $(
                                'a[data-productid = "' +
                                array_product_id[product_wishlist]
                                    .fk_produit_id +
                                '"]'
                            ).attr(
                                "data-wishlistproductid",
                                array_product_id[product_wishlist]
                                    .wishlist_product_id
                            );
                        }
                    }
                }
            },
        });
    }
}

/**
 * Called upon color change in product page
 **/
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        itm_rr_id = itm_rr_id !== '' ? '_' + itm_rr_id.split('_')[1] : '';
        loadProduct.call(form, 'couleur', 'product', itm_rr_id);
    }
}

/**
 * Called upon size change in product page
 **/
function onItemSizeChange(form, event, itm_rr_id) {
    let is_wishlist = $('#wishlist').length > 0;

    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }

    let is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        if (!is_achat_express_v2) {
            $('#btn_add_cart').val($('#btn_add_cart').data('label'));
            $('#button_add_float .gt_add').html($('#btn_add_cart').data('label'));
        }

        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });

            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        if (!is_achat_express_v2) {
            // Check if the product is already in the wishlist
            checkProductInWishlist();
        }

        // Depending on the color, check if the product is available
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id);

        // WPOS Exclusive - Get if product is available in store use in WPOS
        let idStore = getCookie('wshopStoreId');
        let idProduct = getCookie('p_id');
        if (idStore != undefined && idStore > 0) {
            getProductInfoByStore(idProduct, $("#couleurProd").val(), $("#tailleProd").val(), idStore);
        } else if (idStore == undefined || idStore == null || idStore == 0) {
            idProduct = id;
        }

        if ($('.button_ask').length == 0) {
            var data = {
                produitId: idProduct,
                colorId: $("#couleurProd").val(),
                sizeId: $("#tailleProd").val(),
                json: '1'
            };

            $.ajax({
                type: 'post',
                data: data,
                url: path_relative_root + 'ajax_get_stock_by_store.php',
                success: function (res) {
                    showSimpleBloc = false;
                    showMultipleBloc = false;
                    if (res['stock'] <= availabilityInStoreStockLevelLow || res['store'][0].magasin_click_and_go == 0) {
                        traduction = Translator.translate('store_locator_product_unavailable')
                        classe = '';
                        showSimpleBloc = true;
                    } else if (res['stock'] > availabilityInStoreStockLevelLow && res['stock'] <= availabilityInStoreStockLevelMedium) {
                        traduction = Translator.translate('store_locator_product_limited')
                        classe = 'limited';
                        showMultipleBloc = true;
                    } else {
                        traduction = Translator.translate('store_locator_product_available')
                        classe = 'available';
                        showMultipleBloc = true;
                    }
                    if (showSimpleBloc) {
                        $("#bloc_availability .disponibility_other_store").show();
                        $("#bloc_availability .disponibility_change").hide();
                        $("#bloc_availability .disponibility_retire").hide();
                    } else if (showMultipleBloc) {
                        $("#bloc_availability .disponibility_other_store").hide();
                        $("#bloc_availability .disponibility_change").show();
                        $("#bloc_availability .disponibility_retire").show();
                    }
                    $(".clickandgo_product_info .availability").removeClass('limited');
                    $(".clickandgo_product_info .availability").removeClass('available');
                    $(".clickandgo_product_info .availability").addClass(classe);
                    $(".clickandgo_product_info .availability .label_availability").html(traduction);
                }
            });
        }

        // Activate the in-store reservation CTA (Call to Action)
        $('#btn_resa_shop').removeAttr('disabled');
    }
}

//Load only if the page has the class '.product_page'
if ($('.product_page').length) {
    // Initialisation swiper association products
    initializeSwiper('.prodItemSlider', {
        slidesPerView: 1.5,
        spaceBetween: 10,
        allowSlidePrev: true,
        allowSlideNext: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
}

/**
 * Reload product from ref
 **/
function loadProduct(type_filter, id, type_page, itm_rr_id) {
    let qte_itm = 1;
    if (this.form !== undefined && this.form.elements['qteProd'] !== undefined) {
        qte_itm = this.form.elements['qteProd'].value;
    }

    let is_wishlist = false;
    let is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    let data = {};

    if (!is_achat_express_v2) {

        let produit_id_new = $('#prod_' + type_filter).find(':checked').attr('data-produitid');

        data = {
            produit_id: $('#produit_principal').val(),
            produit_principal: $('#produit_principal').val(),
            couleurProd: $('#couleurProd').val(),
            tailleProd: $('#tailleProd').val(),
            qteProd: qte_itm,
            produit_id_new: produit_id_new,
            type: type_filter,
            type_page: (type_page !== undefined && type_page !== '') ? type_page : 'product',
            is_achat_express: 0,
            is_achat_express_v2: (is_achat_express_v2 ? 1 : 0),
        };


    } else {
        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = this.form.elements['isCategSuper'].value;
        var isAssocProduct = (typeof this.form.elements['isAssocProduct'] !== 'undefined' ? this.form.elements['isAssocProduct'].value : 0);
        var new_id = id;
        var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
        var pageType = (isAssocProduct == '1' ? 'assoc' : ((type_page !== undefined && type_page !== '') ? type_page : 'product'));

        data = {
            produit_id: old_id,
            produit_principal: old_id,
            couleurProd: $(this).val(),
            tailleProd: 0,
            qteProd: qte_itm,
            produit_id_new: new_id,
            type: type_filter,
            type_page: pageType,
            is_achat_express: is_wishlist ? 1 : 0,
            is_achat_express_v2: (is_achat_express_v2 ? 1 : 0),
            cpt_prod: cpt_prod - 1,
            isCategSuper: isCategSuper
        };
    }

    if (isGlobalEActive) {
        data.warehouse_only = 1;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function (response) {
            try {
                response = $.parseJSON(response);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success === 'ok') {
                let datas = response.datas;

                if (data.type_page === 'product' || data.type_page === 'assoc') {
                    let $html_main = $(stripCombo(datas.html_main));

                    if (data.is_achat_express_v2) {

                        // Photo et information
                        let itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);

                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input.size_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else {
                        let color = $("#ligne_couleur").html();

                        $('#content_product', '#contents_wrapper').html($('#content_product', $html_main).html());
                        $('.wrap_product_bottom', '#contents_wrapper').html($('.wrap_product_bottom', $html_main).html());
                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input.size').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);
                        $('input#size_' + data.tailleProd).prop('checked', true);
                        // Scroll to the top of the page in the case of a grouping

                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }
                }
            }

            if (wShop.$refs.wAlertestockForm) {
                wShop.$refs.wAlertestockForm.reloadBloc += 1;
            }

        },
        complete: function () {

            if (!data.is_achat_express_v2) {
                checkProductInWishlist();
            }
            initItemSlider();
            swipeDownClose(); // init swipedown close for modals on loaded product

            initSwiperSlide(); // Init swiper slider on the page

            $('#shade').click(); // in case of color change, to hide shade and remove noscroll

            if (typeof lazyloadImage === 'function') {
                lazyloadImage();
            }

            initSwiperProduct(); // Init swiper slider on the page
        }
    });
}

/**
 * Refresh available quantity
 **/
function getAvailableQuantity(id, qty, from, event, itm_rr_id, RefreshQuantity = true) {
    let addProductToCart = false;
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    let is_achat_express = $('#is_achat_express_v2').val() == 1;
    let elem = is_achat_express ? '_' + id + itm_rr_id : '';
    let productId = form.getValue('produit_id');
    let displayQuantity = ($('.display_quantity').length > 0 && $('.display_quantity').val() === '1');
    let productColor = form.getValue('idCouleur_' + id + itm_rr_id);

    if (typeof (productColor) == 'undefined') {
        productColor = form.getValue('idCouleur');
    }

    let val_color = form.getValue(productColor);
    if (val_color === '0') {
        val_color = 'none';
    }

    let productSize = form.getValue('idTaille');
    let val_size = form.getValue(productSize);
    if (val_size === '0') {
        val_size = 'none';
    }

    if (productId !== '' && val_color !== '' && val_size !== '') {
        let data = {
            idprod: productId,
            idcouleur: val_color,
            idtaille: val_size,
            isGlobalEActive: isGlobalEActive,
            json: '1'
        };

        if (from === 'basket') {
            data.suff = productId;
        }

        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {

                // Refresh bloc quantity depending on the size selected
                if (RefreshQuantity && displayQuantity && typeof res.content !== "undefined") {

                    let qteElem = $("#liste_qte");

                    if (qteElem.length) {
                        qteElem.html(res.content);

                        if (!res.inventory.enableAddToCart) {
                            qteElem.addClass('cache');
                        } else {
                            qteElem.removeClass('cache');
                        }
                    }
                }

                if (is_achat_express) {

                    if (!res.inventory.enableAddToCart) {

                        // Display bloc alert stock
                        $("#bloc_add_alert" + elem).show();
                        $("#bloc_size_not_available" + elem).show();

                        // Hide bloc ajout panier
                        $("#prod_info" + elem + " .productSizeFieldset").hide();
                    } else {
                        addProductToCart = true;
                        $("#bloc_add_alert" + elem).hide();
                        $("#bloc_size_not_available" + elem).hide();
                        $("#prod_info" + elem + " .rollover.achat_express").hide();
                        $("#prod_info" + elem + " .rollover_left").show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                        $("#prod_info" + elem + " .productSizeFieldset").show();
                    }

                } else { //Fiche produit

                    // Reset bloc alert stock
                    $(".prod_page_bot .bloc_add_alert_form").hide();
                    $(".prod_page_bot .bloc_add_alert_confirmation").hide();
                    $(".prod_page_bot .bloc_add_alert_erreur").hide();

                    if (!res.inventory.enableAddToCart) {
                        $("#bloc_add_alert").removeClass("cache");
                        $("#btn_alert_stock").show();
                        $(".prod_page_bot #size_is_not_available").show();
                        $("#bloc_add_basket").addClass("inactiv").hide();
                        $("#btn_add_cart").addClass("cache");
                        $('#btn_add_cart').prop('disabled', true);
                        $('#button_add_float').prop('disabled', true);
                        $('#button_add_float').addClass("inactiv");
                    } else {
                        $("#bloc_add_alert").addClass("cache");
                        $("#btn_alert_stock").hide();
                        $(".prod_page_bot #size_is_not_available").hide();
                        $("#bloc_add_basket").removeClass("inactiv").show();
                        $("#btn_add_cart").removeClass("cache");
                        $('#btn_add_cart').prop('disabled', false);
                        $('#button_add_float').prop('disabled', false);
                        $('#button_add_float').removeClass("inactiv");
                    }
                }

                // Custom quantity selector update for FP
                if ($('#product_stock_counter').length && parseInt(res.inventory.stockQuantity)) {
                    $('#product_stock_counter').val(parseInt(res.inventory.stockQuantity));
                }
            }
        });

        if (addProductToCart) {
            waitingAddToBasket(event, form, from, itm_rr_id);
        }
    }
}

/**
 * Check product if exist in wishlist
 **/
function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: { 'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {

    if (in_wishlist) {
        $(".addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $(".addToWishlistButton").removeClass("existToWishlistButton");
    }

}

/* ------------------------------ ACHAT EXPRESS ------------------------------ */
function openColorAchatExpressV2() {
    var $div_color = $(this.form).find(".productColorFieldset");
    var $div_size = $(this.form).find(".productSizeFieldset");
    var $div_alert_stock = $(this.form).find(".bloc_add_alert");
    var lightbox = $(this.form).find(".achat_express");

    /* Hiding previous selections */
    $div_alert_stock.hide();
    $div_size.removeClass("actif");

    /* Display new selection */
    $("#shade").addClass("isExpress").addClass("visible");
    $("#shade").on("click touch", function () {
        $('body').removeClass('noscroll');
        $("#shade").removeClass("isExpress").removeClass("visible");
        $div_color.removeClass("actif");
        lightbox.hide();
    });

    $('body').addClass('noscroll');
    $div_color.addClass("actif");
    lightbox.show();
}


function openSizeAchatExpressV2() {

    var form = this.form;
    var $div_color = $(form).find('.productColorFieldset');
    var $div_size = $(form).find('.productSizeFieldset');
    var size_id = $(form).find('input[name="tailleProd"]').val();
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    var div_alert_stock_title = $(this.form).find('.alert_stock');
    var div_alert_stock_txt = $(this.form).find('.bloc_add_alert_form');
    var lightbox = $(form).find('.achat_express');

    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');
        let move_left = $(parent_block).css('transform').split(',')[4];

        $(parent_block).css({ "left": move_left + "px" });
    }

    /* Hiding previous selections */
    $div_alert_stock.hide();
    // on a un soucis sur le titre qui disparait sur toutes les alerts stock après en avoir enclenché 1, si le formulaire est affiché on force l'affichage du titre sinon c'est qu'on affiche la confirmation et on ne l'affiche pas
    if (div_alert_stock_txt.length && !div_alert_stock_txt[0].getAttribute("style")) {
        div_alert_stock_title.show();
    }
    $div_color.removeClass('actif');

    if ($('#displaySizeAlert').hasClass('open')) {

        $('#displaySizeAlert').removeClass('open');
    }

    if (size_id !== 'none') {

        form.elements['itm_size'].checked = false;
    }

    if (!$("#shade").hasClass('visible')) {

        $("#shade").addClass("isExpress").addClass("visible");
        $('body').addClass('noscroll');
    } else {

        $("#shade").addClass("isExpress");
    }

    if ($(this).closest('.swiper-container').length) {
        $(this).closest('.swiper-container').addClass('achatExpressOpened');
    }

    $("#shade").on('click touch', function () {

        $("#shade").removeClass("isExpress").removeClass("visible");
        $("#displaySizeAlert").removeClass("open");
        $(".productSizeFieldset").removeClass("actif");
        $(".bloc_add_alert").hide();
        $('body').removeClass('noscroll');
        $div_size.removeClass('actif');
        lightbox.hide();

        if ($('#productVisitedSwiper .swiper-wrapper').length) {
            $('#productVisitedSwiper .swiper-wrapper').css({ "left": "" });
        }

        var $productSwiper = $('#productVisitedSwiper');

        if ($productSwiper.hasClass('achatExpressOpened')) {
            $productSwiper.removeClass('achatExpressOpened').find('.productSizeFieldset').show();
            $productSwiper.find('.form_itm.size input').prop('checked', false);
        }
    });

    if (size_id === 'none') {

        sendFormUpdateCart.call(form);
    } else {

        $div_size.addClass('actif');
        lightbox.show();
        $('body').addClass('noscroll');
    }

    var product_id = parseInt($(form).find('input[name=produit_id]').val());

    if (array_colisages[product_id] == undefined) {
        var array_product_ids = [];
        var array_products = $(document).find('input[name=produit_id]');

        Object.values(array_products).forEach(function (value) {
            if (value.id && value.id.indexOf('produit_id_') >= 0 && array_colisages[$(value).val()] == undefined) {
                array_product_ids.push($(value).val());
            }
        });

        if (array_product_ids.length > 0) {
            var data = { produit_id: array_product_ids };

            if (isGlobalEActive) {
                data.warehouse_only = 1;
            }

            $.ajax({
                url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
                type: 'post',
                data: data,
                async: false,
                success: function (res) {
                    try {
                        res = $.parseJSON(res);
                    } catch (e) {
                        console.error("parseJSON");

                        return;
                    }

                    if (res.success && res.result) {
                        Object.entries(res.result).forEach(function (entry) {
                            const [idProduct, objProduct] = entry;
                            array_colisages[idProduct] = objProduct;
                        });
                    }
                }
            });
        }
    }

    if (array_colisages[product_id] != undefined) {
        var sizes_tab = array_colisages[product_id][$(form).find('input[name=couleurProd]').val()];

        var size_box, is_disabled;
        var has_stock = false;
        for (size_id in sizes_tab) {
            if (sizes_tab.hasOwnProperty(size_id)) {
                size_box = $(form).find('[name="itm_size"]').filter('[value="' + size_id + '"]');
                is_disabled = !sizes_tab[size_id];

                if (!is_disabled) {
                    has_stock = true;
                    size_box.removeClass('no_stock');
                    size_box.removeClass('disabled');
                } else {
                    size_box.addClass('no_stock');
                    size_box.addClass('disabled');
                }
            }
        }
    }

    if (isGlobalEActive) {

        $('.no_stock_entrepot').each(function () {

            if ($(this).html() == '') {

                if (!$(this).prev().hasClass('no_stock')) {

                    $(this).prev().addClass('no_stock');
                    $(this).prev().show();
                }
            } else {

                $(this).show();
            }
        });
    }

    // Destroy existing Swiper if it exists
    if (basketSizeSwiperElmt && basketSizeSwiperElmt.destroy) {
        basketSizeSwiperElmt.destroy(true, true); // true to destroy the DOM and reset its state
        basketSizeSwiperElmt = null; // Clear the reference
    }

    // Call the basketSizeSwiper function to initialize a new Swiper
    basketSizeSwiper($(this.form).attr('id'));
}

// /**
//  * In the product information sheet, display an email field so that we can be notified when the product is available.
//  */
function displayAlertStock() {

    if (!$('#btn_add_alert').is(':visible')) {
        $('#btn_add_alert').show();
    }

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if (isFormVisible) {
            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('#bloc_add_alert_form').slideDown('fast');
        $('#btn_alert_stock').hide();
    }
}

function hideAchatExpress() { // override app to had noscroll removal on body
    $('body').removeClass('noscroll');
    $("#shade").removeClass("visible").removeClass("isExpress");
    $(this.form).find('.achat_express').hide();

    $(this.form).find('.actif').removeClass('actif');

    $(this.form).find('.actif').removeClass('actif');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }

    $(this).closest('.swiper-container').removeClass('achatExpressOpened');

    if ($(this.form).closest('.swiper-wrapper').length) {
        let parent_block = $(this.form).closest('.swiper-wrapper');

        $(parent_block).css({ "left": "" });
    }
}

function checkQty(datas) {
    let hasStock = false;

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: {
            idprod: datas.idProduit,
            idcouleur: datas.idCouleur,
            idtaille: datas.idTaille,
            isGlobalEActive: isGlobalEActive,
            json: '1'
        },
        success: function (res) {
            let $bloc_alert = res.enableAlertStock ? $("#bloc_add_alert" + datas.suffix) : $("#bloc_size_not_available" + datas.suffix);

            if (!res.inventory.enableAddToCart) {

                if ($bloc_alert != undefined && $bloc_alert.length > 0) {
                    $bloc_alert.find('.bloc_add_alert_confirmation').hide();
                    $bloc_alert.find('.alert_stock').show();
                    $bloc_alert.find('.bloc_add_alert_form').show();
                    $bloc_alert.show();
                }

                $("#ligne_pointure" + datas.suffix).removeClass('actif');
            } else {
                hasStock = true;
            }
        }
    });

    return hasStock;
}

// _app override / Common views between Desktop and Mobile
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, prodId;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $('#cart_btn').data('cart', response);

                /**
                 * Move out of stock products to the top of the cart
                 */

                updateCartElements();

                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'less') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "remove_from_cart");
                }

                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'plus') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "add_to_cart");
                }
            }
        });
    }
}

/**
 * After ajax called in basket (also called in _app)
 */
function updateCartElements() {
    var cartAssocSwiper = $("#cart_slider_container");

    // Placement of associations just after cart summary
    cartAssocSwiper.insertAfter($("#cart_total"));

    /**
     * Cart associations slider init
     */
    setTimeout(function () {
        if (cartAssocSwiper.length) {
            cartAssocSwiper.addClass("appear");
        }
    }, 300);

    cart_switch_view();
    moveProductOutOfStock();
}

/* Switch cart btn class depending on its viewport position
*/
function formPanierClass() {
    let formPanier_container = $(".formPanier_container");
    let elementBottom =
        formPanier_container.offset().top + formPanier_container.outerHeight();

    if ($(document).scrollTop() + $(window).height() <= elementBottom) {
        formPanier_container.addClass("fixed");
    } else {
        formPanier_container.removeClass("fixed");
    }

    $(window).on("scroll", function () {
        if ($(document).scrollTop() + $(window).height() <= elementBottom) {
            formPanier_container.addClass("fixed");
        } else {
            formPanier_container.removeClass("fixed");
        }
    });
}

/* Switch between cart and wishlist view in basket */
function cart_switch_view() {
    if ($('.toggleCartTypeBtn').length) {

        let toggleCart = $('.toggleCartTypeBtn');
        let cartContent = $('.cart_main_table.basket');
        let wishContent = $('.cart_main_table.wishlist');
        let cartSummary = $('#tunnel_right_col');

        toggleCart.on('click', function () {

            toggleCart.removeClass('actif');
            $(this).addClass('actif');
            $('.cart_main_table').removeClass('displayed');

            if ($(this).hasClass('basket')) {
                cartContent.addClass('displayed');
                cartSummary.removeClass('noDisplay');
            } else {
                wishContent.addClass('displayed');
                cartSummary.addClass('noDisplay');
            }
        });

        $(wishContent).insertAfter(cartContent);
    }
}

/* STORE LOCATOR */
function creerLigneListe(
    cpt,
    id,
    nom,
    adresse,
    ville,
    cp,
    pays,
    currently_open,
    horaire_today,
    distance,
    marqueur,
    picture_filtre,
    magasinId,
    stock,
    stockStatus,
    stock_quantity,
    clientId,
    favorite,
    beContacted,
    flag_available,
    url,
    shop_filter,
    telephone,
    query,
    lat,
    lng
) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement("li");
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = "elem-list-store";

    if (favorite == "1") {
        choose_fav = Translator.translate("actual_favorite_store");
    } else {
        choose_fav = Translator.translate("choose_as_favorite_store");
    }

    /***** Product stock infos *****/
    if (stock_quantity == "undefined" || stock_quantity == "") {
        stock_status_msg = "unknow_stock";
    } else {
        var in_stock = stock_quantity >= 6 && stock_quantity <= 9999;
        var limited_stock = stock_quantity >= 1 && stock_quantity <= 5;
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = "";
        if (in_stock) {
            stock_status_msg = "in_stock";
        }
        if (limited_stock) {
            stock_status_msg = "limited_stock";
        }
        if (not_in_stock) {
            stock_status_msg = "not_in_stock";
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = "to_be_confirmed_stock";
        }
        if (can_be_ordered_stock) {
            stock_status_msg = "can_be_ordered_stock";
        }
    }

    var info_horaire_today = !horaire_today
        ? Translator.translate("store_closed")
        : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_closed";
    var ligneListe =
        '<div class="elem_list_contents">' + '<div class="nom_store" onclick="geolocGoto(\'' + lat + '\',\'' + lng + '\')">';
    if (distance) {
        ligneListe +=
            '<h2 class="title">' +
            nom +
            '<p class="distance">' +
            "(" +
            distance +
            "km)" +
            "</p></h2>";
    } else {
        ligneListe += '<h2 class="title">' + nom + "</h2>";
    }
    ligneListe += "</div>" + '<div class="content_store">';

    if ($("body.product_page").length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url
            }'">`;
    } else {
        ligneListe += '<div class="detail_store">';
    }
    ligneListe +=
        '<div class="col-1">' +
        '<span class="rue_store">' +
        adresse.toLowerCase() +
        "</span>" +
        '<span class="ville_store">' +
        cp +
        " " +
        ville.charAt(0).toUpperCase() +
        ville.slice(1).toLowerCase() +
        " - " +
        '<span class="pays_store">' +
        pays +
        "</span>" +
        "</span>" +
        '<span class="tel_store">' +
        telephone +
        "</span></div>";

    var is_page_product = $("body.product_page").length == 1;

    if (
        (info_horaire_today !== "" && currently_open) ||
        (!currently_open && horaire_today)
    ) {
        ligneListe +=
            '<div class="col-2"><p class="horaire_today puce_' +
            class_horaire_today +
            '">' +
            info_horaire_today +
            "</p></div>";
    }

    ligneListe += "</div>";
    if (document.getElementById("produit_id")) {
        /***** Product stock infos msg *****/
        if (stock != "undefined") {
            ligneListe +=
                '<p class="stock_status ' +
                stock_status_msg +
                '">' +
                Translator.translate(stock_status_msg + "_msg") +
                "</p>";
        }
    }

    if (is_page_product) {
        if (beContacted == 1) {
            if ($("body.product_page.mobile").length) {
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChangeMobile(' +
                    id +
                    ')">' +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe +=
                    '<div class="availableTxt store_more" onclick="handleChange(\'' +
                    id +
                    "','" +
                    nom_mag +
                    "')\">" +
                    "<span>" +
                    txt_available_mag +
                    "</span>" +
                    "</div>" +
                    "</div>" +
                    "</div>";
            }
        } else {
            ligneListe += "</div>" + "</div>";
        }
    }

    isFavorite = favorite ? "favorite_store_js" : "";
    ligneListe +=
        '<a href="javascript:;" class="addfavorite" id="store' +
        magasinId +
        '" onclick="addToMyFavorite(\'' +
        magasinId +
        "', '" +
        clientId +
        "', '" +
        favorite +
        "', '" +
        escape(info_horaire_today) +
        "','" +
        currently_open +
        "','" +
        escape(horaire_today) +
        "','" +
        class_horaire_today +
        "','" +
        stock +
        "','" +
        stockStatus +
        "'); return false;\">";
    ligneListe +=
        '<span class="choose_fav_label" id="favShop-' +
        magasinId +
        '"><span class="' +
        isFavorite +
        '">' +
        choose_fav +
        "</span></span></a>";

    if (!is_page_product) {
        ligneListe +=
            '<div class="store_more"><a onclick="createDataLayer(' +
            id +
            ')" href="' +
            path_relative_root +
            url +
            '">' +
            Translator.translate("afficher_horaires") +
            "</a>" +
            "</div>";
    }

    ligneListe += "</div>" + "</div>" + "</div>";

    li.innerHTML = ligneListe;

    li.addEventListener("click", function () {
        google.maps.event.trigger(marqueur, "click");
    });

    return li;
}

function creerMarqueur(
    point,
    nom,
    adresse,
    ville,
    cp,
    pays,
    horaire,
    telephone,
    email,
    site,
    image_mag,
    type_mag,
    cpt,
    magasin_id,
    url,
    distance,
    horaire_today,
    currently_open
) {
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(
        img,
        // This marker is 24 pixels wide by 29 pixels tall.
        new google.maps.Size(24, 29),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0)
    );

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(
        path_relative_root + "img/indic_carte_ombre.png",
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0)
    );
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image,
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? "" : horaire_today;
    var class_horaire_today = currently_open ? "store_open" : "store_close";
    var currently_open_info = currently_open
        ? Translator.translate("store_opened")
        : Translator.translate("store_closed");

    var cpt_recherche = typeof cpt != "undefined" ? "(" + cpt + ") - " : "";

    if (distance) {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            '</a><span class="dist">(' +
            distance +
            " km)</span>";
    } else {
        var ligneListe =
            '<div class="info_popup"><div class="name"><a href="' +
            url +
            '">' +
            nom +
            "</a></div>";
    }

    ligneListe +=
        '<div class="adresse">' +
        adresse.toLowerCase() +
        "<br />" +
        cp +
        " " +
        ville.toLowerCase() +
        "<br />" +
        pays.toLowerCase() +
        "</div>";

    if (telephone != "")
        ligneListe += '<div class="coord">' + telephone + "</div>";

    if (site != "")
        if (!http.test(site)) {
            ligneListe +=
                '<a href="http://' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        } else {
            ligneListe +=
                '<a href="' +
                site +
                '" class="site" target="_blank">' +
                site +
                "</a>";
        }

    if (horaire != "")
        if (class_horaire_today == "store_open") {
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                "</span>" +
                info_horaire_today +
                '</div><div class="horaire_info">' +
                horaire +
                "</div>";
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe +=
                '<div class="horaire_title"><span class = "' +
                class_horaire_today +
                '">' +
                currently_open_info +
                '</span></div><div class="horaire_info">' +
                horaire +
                "</div>";
        }
    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate(
        "go_to_store"
    )}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate(
        "voir_magasin"
    )}</a></div>
        </div>`;
    // Add marker image inside tooltip
    ligneListe += `<div class="store_marker"><img src="${image.url}"/></div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id;

    markersArray.push(marqueur);

    return marqueur;
}

/**
 * Disable add to cart btn and display a rollover text information.
 */
function disableAddCart() {
    "use strict";
    var addCartWrapper = $(".btnAddBasketWrapper");
    var addCartStickyWrapper = $("#button_add_float");
    var addCartBtn = $(".btnAddBasket");

    addCartWrapper.addClass("inactiv");
    addCartStickyWrapper.addClass("inactiv");
    addCartBtn.prop("disabled", true);

    // Special case for personalization
    if ($('#btn_custom').length) {
        $('#btn_custom').prop("disabled", true);
    }
}

/**
 * Enable add to cart btn
 */
function enableAddCart() {
    "use strict";
    var addCartWrapper = $(".btnAddBasketWrapper");
    var addCartStickyWrapper = $("#button_add_float");
    var addCartBtn = $(".btnAddBasket");
    var disabledBtnInfos = $(".disabledBtnInfos");

    disabledBtnInfos.text("");
    addCartWrapper.removeClass("inactiv");
    addCartStickyWrapper.removeClass("inactiv");
    addCartBtn.prop("disabled", false);

    // Special case for personalization
    if ($('#btn_custom').length) {
        $('#btn_custom').prop("disabled", false);
    }
}

// Initialize and filter and show corresponding questions according to user's keyword
function faqinit(array_questions) {
    $(document).on("change keyup focus", "#faq_search", function () {
        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {
            $(".search_results li").show().removeClass("hasMatch");
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $(".search_results").show();
                    $("li#brand_" + val["id"]).addClass("hasMatch");
                }
            });

            $(".brand_item:not(.hasMatch)").hide();

            if (array_match.length == 0) {
                $(".search_results").hide();
            }
        } else {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    var click_in_process = false;

    $(".search_results").mousedown(function () {
        click_in_process = true;
    });

    $(".search_results").mouseup(function () {
        click_in_process = false;
        $("#faq_search").focus();
    });

    $("#faq_search").blur(function () {
        if (!click_in_process) {
            $(".search_results").hide();
            $(".search_results li").show().removeClass("hasMatch");
        }
    });

    $("#theme_global .theme").on("click", function () {
        var id = $(this).data("id");
        var theme = $("#theme_page");
        $('#theme_global .theme').removeClass('selected');
        $(this).addClass('selected');
        $("#theme_global").removeClass("actif");
        $(".title_detail_sub").css("display", "none");
        theme.addClass("actif");
        showTabFaq(id, 1);
        $("html, body").animate(
            { scrollTop: $(".faq_theme_" + id + "").offset().top - 100 },
            1000
        );
    });

    $("#theme_page .theme").on("click", function () {
        var id = $(this).data("id");
        showTabFaq(id, 1);
    });
}

function researchFaq(node_id, level, question_id) {
    $(".search_results").hide();
    $(".search_results li").show().removeClass("hasMatch");

    if ($("#theme_global").hasClass("actif")) {
        $("#theme_global").removeClass("actif");
        $("#theme_page").addClass("actif");
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }
    showTabFaq(node_id, level, question_id);
    $("html, body").animate(
        { scrollTop: $(".faq_question_" + question_id + "").offset().top - 130 },
        1000
    );
}

// add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn) {

    $(elemBtn).addClass('processing');
    var prodId = $(elemBtn).attr("data-produit-id");
    var colorId = $(elemBtn).attr("data-color-id");
    var sizeId = $(elemBtn).attr("data-size-id");

    var isOperatedByGlobalE = false;
    if (typeof GEM_Services != "undefined" && typeof GEM_Services.ConfigurationsService != "undefined" && typeof GEM_Services.ConfigurationsService.Instance != "undefined" && typeof GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings != "undefined") {
        isOperatedByGlobalE = GEM_Services.ConfigurationsService.Instance.UserLocalizeSettings.IsOperatedByGlobalE;
    } else if (typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined) {
        isOperatedByGlobalE = GEP.isOperatedByGlobale;
    }

    let datas = {
        data: $(elemBtn).attr("data-id"),
        isOperatedByGlobalE: isOperatedByGlobalE,
    };

    if (prodId !== undefined) {
        datas.productId = prodId;
    }

    if (colorId !== undefined) {
        datas.colorId = colorId;
    }

    if (sizeId !== undefined) {
        datas.sizeId = sizeId;
    }

    $.post(path_relative_root + "ajax_add_panier_wishlist.php", datas, function (resp) {
        if (resp === "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}


/** if no change move to common **/
function display_lightbox_specifique() {

    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php');
    if (html) {
        var $lighbox = $('#lightbox_achat_express');
        var $shad = $('#shad');
        $lighbox
            .html('<div style="position: relative">' + html + '</div>')
            .appendTo('body')
            .show();
        $shad
            .show()
            .unbind('click.achat_express')
            .bind('click.achat_express', function () {
                $("#alertbox").remove();
            });
    }
}

$(window).on("add_to_cart_success", function (data) {

    if (typeof (data.detail[7]) != 'undefined') {
        let initialCount = (typeof ($("#cart_btn").data('cart')) !== 'undefined' ? $("#cart_btn").attr("data-cart") : 0);
        let finalCount = parseInt(initialCount) + parseInt(data.detail[7]);

        if (finalCount > 0) {
            $("#cart_btn").addClass('count').attr("data-cart", finalCount);
        } else {
            $("#cart_btn").removeClass('count').attr("data-cart", 0);
        }
    }
    if (document.getElementById('item_added')) {
        // Ouverture de la popup d'information
        modBoxOpen.call(document.getElementById('item_added'));
    } else {
        location.reload();
    }
});

$(window).on("add_to_cart_error", function (data) {
    modBoxOpen.call(document.getElementById('item_error'));
});

function initSwiperSlide() {

    // Load only if the page has the class '.slider-cover-module'
    if ($('.cms-page-module.slider-cover-module').length) {
        // Initialization of swiper module 1 CMS
        initializeSwiper('.slider-cover-module .swiper-container', {
            pagination: '.swiper-pagination',
        });
    }

    // Initialization of module 3 CMS
    if ($('.cms-page-module-body.image-text-1').length) {
        initializeSwiper('.cms-page-module-body.image-text-1', {
            slidesPerView: 1,
            nextButton: '.pictures_text-next',
            prevButton: '.pictures_text-prev',
            pagination: ".pictures_text-pagination",
        });
    }

    // Initialization of module 5 CMS
    if ($('.cms-page-module-body.text-image').length) {
        initializeSwiper('.cms-page-module-body.text-image', {
            slidesPerView: 1,
            nextButton: '.pictures_text-next',
            prevButton: '.pictures_text-prev',
            pagination: ".pictures_text-pagination",
        });
    }

    // Initialization of swiper module 6 home
    if ($('.cms-page-module.tg-module').length) {
        initializeSwiper('.cms-page-module.tg-module .main-swiper', {
            slidesPerView: 1.5,
            spaceBetween: 10,
        });
    }
}

function sendMailAlertStock(type_alert, form, itm_rr_id) {
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '_' + id + itm_rr_id;

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();

    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {
        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {
        $('#mail_alerte_stock' + elem).removeClass('inputErr');

        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('#bloc_add_alert' + elem + ' .alert_stock').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('#bloc_add_alert' + elem + ' .title_disponibilite').hide();
                // FP
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
                $('.bloc_add_alert' + elem + ' .title_disponibilite').hide();
            },
            fail: function () {
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('#bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
                // FP
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

/*Show the button notify availability on cart page*/
function updateCartUI() {
    if ($('body').hasClass('cart')) {
        $('.cart_product_line .cart_product_desc .wrapper_cart_product_desc form:not(.cart_item_actions_form)').each(function () {
            $(this).closest('.cart_product_line').append($(this));
        });
    }
}

// Extra function executed at the end on modBoxClose from _app. Add any custom orders in here
function modBoxCloseExtra() {
    if ($('body').hasClass('noscroll')) {
        $('body').removeClass('noscroll');
    }
}

function closeAlerteStock(elem) {
    var $alertBloc = $('.bloc_add_alert_' + elem);
    var $prodInfo = $('#prod_info_' + elem);
    var $lignePointure = $('#ligne_pointure_' + elem);
    var $ligneCouleur = $('#ligne_couleur_' + elem);

    // Hide bloc alert stock
    $alertBloc.hide();
    $prodInfo.find('.rollover_left, .bloc_add_color').show();
    $prodInfo.find('.bloc_add_color').removeClass('loading');
    $lignePointure.find('.form_itm input').prop('checked', false);

    // Show bloc colors and size
    $lignePointure.show();
    $ligneCouleur.show();

    // Reset mail input
    $alertBloc.find('.alert_stock, .bloc_add_alert_form').show();
    $alertBloc.find('.bloc_add_alert_confirmation').hide();
}

function closeSizeNotAvailable(elem) {
    // Hide bloc bloc_size_not_available
    $("#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $("#prod_info_" + elem + " .rollover_left").show();
    $("#ligne_pointure_" + elem).show();
}

function initSwiperProduct() {
    var contentMovable = $("#content_movable");
    //set slider product
    if (prod_slider && $(".swiper-slide", prod_slider).length > 1) {
        window.prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
            slidesPerView: 1,
            mode: 'horizontal',
            centeredSlides: true,
            pagination: '.swiper-pagination',
            paginationClickable: true,
            preloadImages: true,
            lazyLoading: true,
            loop: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev'
        });
    }

    setTimeout(function () {
        $(".zoom_indicator").fadeOut(800)
    }, 2500);

    $(".jqzoom").on("doubletap", function () {
        zoomImg.call(this);
        $("#zoomBox").addClass("active");

        contentMovable.addClass("visible");
        var zoomboxWidth = $("body").width(),
            scrollTo = zoomboxWidth / 2;
        setTimeout(function () {
            $("#zoomBox").scrollLeft(scrollTo);
        }, "slow");
    });

    contentMovable.on("doubletap", function () {
        $(this).removeClass("visible");
        $("#zoomBox .btn_close").trigger("click");
    });
}